import { FC, useEffect, useState } from 'react';

import { CloudinaryImage, cloudinaryLoader } from 'components/common/CloudinaryImage';
import { COLORS } from 'consts';
import { useViewport } from 'context/viewport';

import { PoweredByBezel } from './PoweredByBezel';

export const HeroSectionTestIds = {
  container: 'hero-section-container'
};

export interface HeroSectionProps {
  imageUrl?: string;
  sellerLogoDark?: string;
  sellerLogoLight?: string;
  caption?: string;
  title?: string;
}

const SellerLogo = ({ imageSrc }: { imageSrc: string }) => (
  <CloudinaryImage src={imageSrc} width={190} height={52} alt='Bezel Logo' style={{ objectFit: 'contain' }} />
);

export const HeroSection: FC<HeroSectionProps> = ({ imageUrl, sellerLogoDark, sellerLogoLight, caption, title }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isTabletOrMobile } = useViewport();

  const imageHeight = isTabletOrMobile ? '380px' : '580px';

  const handleScroll = () => setScrollPosition(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getOpacity = () => {
    const opacity = Math.min(1, Math.max(0, (scrollPosition - parseInt(imageHeight, 10) + 100) / 100));

    return { light: 1 - opacity, dark: opacity };
  };

  return (
    <div data-testid={HeroSectionTestIds.container} className='position-relative w-100'>
      <div
        className='position-absolute w-100'
        style={{
          height: imageHeight,
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0,0,0,1) 100%)`
        }}
      />
      <div
        style={{
          backgroundImage: imageUrl && `url(${cloudinaryLoader({ src: imageUrl, width: 3640, quality: 100 })})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: imageHeight,
          backgroundSize: 'cover',
          zIndex: 5
        }}
      >
        <div
          className='w-100 py-3 px-4 d-flex align-items-center position-fixed justify-content-between'
          style={{ pointerEvents: 'none', zIndex: 10 }}
        >
          <div className='position-relative'>
            <div style={{ opacity: getOpacity().light }}>
              {sellerLogoLight ? (
                <SellerLogo imageSrc={sellerLogoLight} />
              ) : (
                <div className='riforma-medium fs-20px text-white'>{title}</div>
              )}
            </div>

            <div className='position-absolute' style={{ top: 0, opacity: getOpacity().dark }}>
              {sellerLogoDark ? (
                <SellerLogo imageSrc={sellerLogoDark} />
              ) : (
                <div className='riforma-medium fs-20px'>{title}</div>
              )}
            </div>
          </div>
          <div className='position-relative'>
            <div style={{ opacity: getOpacity().light }}>
              <PoweredByBezel />
            </div>
            <div className='position-absolute' style={{ top: 0, opacity: getOpacity().dark }}>
              <PoweredByBezel isDark />
            </div>
          </div>
        </div>
        <div
          className='position-fixed d-flex flex-column justify-content-center align-items-center w-100'
          style={{ height: imageHeight, color: COLORS.primaryLight, opacity: getOpacity().light }}
        >
          <h1 className='fs-12px opacity-60 line-height-20px mb-3 text-uppercase'>{title}</h1>
          <div className='fs-32px line-height-48px text-center' style={{ maxWidth: '500px' }}>
            {caption || <div style={{ maxWidth: '350px' }}>Shop our selection of luxury watches</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
