import { NextSeo, NextSeoProps, OrganizationJsonLd, OrganizationJsonLdProps } from 'next-seo';

import { useBranding } from 'context/branding';
import { getHttpsLink } from 'lib/helpers';
import { pageRoutes } from 'lib/routes';
import { SellerSiteMetadata } from 'types';

import { getDefaultSellerSiteSEO } from './common/SellersDefaultSEO/helpers';

interface SellersHeadProps {
  sellerSiteMetadata?: SellerSiteMetadata | null;
}
const description =
  'Shop for thousands of the most collectible watches online. Find Rolex, Cartier, Patek Philippe, Panerai, IWC, and more. All of our watches are authenticated in-house by our team of specialists and watchmakers with decades of experience in luxury watches.';

export const SellersHead: React.FC<SellersHeadProps> = ({ sellerSiteMetadata }) => {
  const { sellerSiteMetadata: contextSellerSiteMetadata } = useBranding();

  const metadata = sellerSiteMetadata || contextSellerSiteMetadata;

  const defaultSellerSiteSEO = getDefaultSellerSiteSEO(sellerSiteMetadata);

  const config: NextSeoProps = {
    ...defaultSellerSiteSEO,
    title: metadata?.businessName,
    description: metadata?.aboutBody || description,
    openGraph: {
      ...defaultSellerSiteSEO?.openGraph, // Workaround OG properties get overriden whenever next-seo initialize a new OG object
      title: metadata?.businessName,
      description: metadata?.aboutBody || description,
      ...(metadata?.darkLogoImage?.optimizedUrl && {
        images: [
          {
            url: metadata?.darkLogoImage?.optimizedUrl,
            alt: `${metadata?.businessName} Site Logo`,
            type: 'image/png'
          }
        ]
      })
    }
  };

  const jsonLd: OrganizationJsonLdProps | null | undefined = metadata && {
    type: 'Organization',
    name: metadata.businessName,
    legalName: metadata.businessName,
    alternateName: metadata.sellerProfile.businessName,
    description: metadata.aboutBody,
    logo: metadata.faviconImage?.optimizedUrl || metadata.darkLogoImage?.optimizedUrl,
    address: {
      streetAddress: metadata.businessAddress?.line1 || metadata.businessAddress?.line2,
      addressLocality: metadata.businessAddress?.city,
      addressRegion: metadata.businessAddress?.state,
      postalCode: metadata.businessAddress?.postalCode,
      addressCountry: metadata.businessAddress?.country
    },
    sameAs: metadata.socialLinks?.map(({ link }) => getHttpsLink(link)),
    contactPoint: [
      {
        telephone: metadata.phoneNumber,
        contactType: 'Customer Service',
        email: metadata.contactEmail,
        availableLanguage: 'English',
        areaServed: metadata.sellerProfile.shippingAddress?.country
      }
    ],
    url: metadata.domain ? getHttpsLink(metadata.domain) : pageRoutes.sellerWebsite(metadata)
  };

  return (
    <>
      <NextSeo {...config} />
      {jsonLd && <OrganizationJsonLd {...jsonLd} />}
    </>
  );
};
