import { FC } from 'react';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { useViewport } from 'context/viewport';
import { getTitleFromListing } from 'lib/getTitleFromListing';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/ListingCard.module.scss';
import { StyledComponent } from 'types';
import { Listing } from 'types/listing';
import { formatPrice, getListingStrikethroughPrice, getListingTitleTruncated, uppercase } from 'utils/text-helpers';

interface ListingSellerCardProps extends StyledComponent {
  listing: Listing;
  listingTitleClassname?: string;
  listingPriceClassname?: string;
}

export const ListingSellerCard: FC<ListingSellerCardProps> = ({
  listing,
  className,
  listingTitleClassname,
  listingPriceClassname,
  style
}) => {
  const { isMobileSmall } = useViewport();

  const collapsedTitle = getListingTitleTruncated(listing, { truncate: 100 });
  const formattedPrice = formatPrice(listing.priceCents);
  const title = getTitleFromListing(listing);

  return (
    <Link
      href={pageRoutes.fullListingUrl({
        brandDisplayName: listing.model.brand.displayName,
        modelDisplayName: listing.model.displayName,
        referenceNumber: listing.model.referenceNumber,
        listingId: listing.id
      })}
      className={classNames(
        'rounded-2 d-flex flex-column p-sm-2',
        {
          [styles['seller-listing-hover']]: !isMobileSmall,
          'text-center': !isMobileSmall,
          'align-items-start px-1': isMobileSmall
        },
        className
      )}
      style={style}
    >
      <div
        className='d-flex flex-column justify-content-around rounded-2 border-muted overflow-hidden w-100'
        style={{ boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.1)' }}
      >
        <CloudinaryImage
          responsive
          alt={`${title} Listing Image`}
          sizes='40vw'
          src={listing?.images?.find(i => i.type === 'FRONT')?.image.optimizedUrl || 'model_placeholder.png'}
        />
      </div>
      {!isMobileSmall && (
        <div className='fs-10px opacity-60 mt-3 mb-1'>REF {uppercase(listing.model?.referenceNumber)}</div>
      )}
      <div
        className={classNames(
          'fs-10px clamp-lines-2 fs-sm-16px line-height-16px line-height-sm-24px text-primary px-1 riforma-regular mt-2',
          listingTitleClassname
        )}
      >
        {collapsedTitle}
      </div>
      <div className={classNames('fs-10px fs-sm-14px px-1 mt-1 mt-md-2', listingPriceClassname)}>
        {formattedPrice}
        {listing.strikethroughPriceCents && (
          <span className='text-secondary text-decoration-line-through ps-2'>
            {getListingStrikethroughPrice(listing)}
          </span>
        )}
      </div>
    </Link>
  );
};
