import { BiLogoTiktok } from '@react-icons/all-files/bi/BiLogoTiktok';
import { BsFacebook } from '@react-icons/all-files/bs/BsFacebook';
import { BsInstagram } from '@react-icons/all-files/bs/BsInstagram';
import { BsLinkedin } from '@react-icons/all-files/bs/BsLinkedin';
import { BsPinterest } from '@react-icons/all-files/bs/BsPinterest';
import { BsYoutube } from '@react-icons/all-files/bs/BsYoutube';
import { IoLogoTumblr } from '@react-icons/all-files/io5/IoLogoTumblr';
import { IconType } from '@react-icons/all-files/lib';
import { MdOutlineWhatsapp } from '@react-icons/all-files/md/MdOutlineWhatsapp';
import { RiThreadsLine } from '@react-icons/all-files/ri/RiThreadsLine';
import { RiTwitterXLine } from '@react-icons/all-files/ri/RiTwitterXLine';

export const socialIconMap: Record<string, IconType> = {
  facebook: BsFacebook,
  instagram: BsInstagram,
  twitter: RiTwitterXLine,
  linkedin: BsLinkedin,
  tiktok: BiLogoTiktok,
  threads: RiThreadsLine,
  whatsapp: MdOutlineWhatsapp,
  pinterest: BsPinterest,
  youtube: BsYoutube,
  tumblr: IoLogoTumblr
};
