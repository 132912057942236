import { FC } from 'react';

import { ContentLoader } from 'components/design-system/ContentLoader';

const loaderTextStyle = { borderRadius: 8, background: '#faf8f6' };

export const SearchNotFoundPlaceholderTestIds = {
  mobileContainer: 'not-found-placeholder-mobile-container',
  desktopContainer: 'not-found-placeholder-desktop-container'
};

const NotFoundPlaceholderMobile = () => (
  <div data-testid={SearchNotFoundPlaceholderTestIds.mobileContainer}>
    <div className='d-flex justify-content-between position-relative flex-wrap'>
      <div className='position-absolute fs-16px line-height-24px text-center w-100' style={{ zIndex: 2, top: '100px' }}>
        No matching results
      </div>
      {[0, 1, 2, 4].map(e => (
        <div key={e} className='d-flex flex-column' style={{ width: '47%' }}>
          <ContentLoader
            borderRadius={16}
            className='w-100 my-2'
            height={180}
            style={{ background: `linear-gradient(180deg, #f2f1ef, #faf8f6)` }}
            enableAnimation={false}
          />
          <ContentLoader
            className='w-100 line-height-18px'
            height={12}
            style={loaderTextStyle}
            enableAnimation={false}
          />
          <ContentLoader
            className='w-50 line-height-18px'
            height={12}
            style={loaderTextStyle}
            enableAnimation={false}
          />
          <ContentLoader
            className='w-30 line-height-18px mb-3'
            height={12}
            style={loaderTextStyle}
            enableAnimation={false}
          />
        </div>
      ))}
    </div>
  </div>
);

const NotFoundPlaceholder = () => (
  <div data-testid={SearchNotFoundPlaceholderTestIds.desktopContainer}>
    <div className='fs-24px line-height-32px my-5 text-center'>No matching results</div>
    <div className='d-flex justify-content-between position-relative'>
      {[0, 1, 2].map(e => (
        <div key={e} className='d-flex flex-column align-items-center w-30'>
          <ContentLoader
            borderRadius={16}
            className='w-100 mb-2'
            height={280}
            style={{ background: `linear-gradient(180deg, #f2f1ef, #faf8f6)` }}
            enableAnimation={false}
          />
          <ContentLoader className='w-50 mb-2' height={16} style={loaderTextStyle} enableAnimation={false} />
          <ContentLoader className='w-80' height={20} style={loaderTextStyle} enableAnimation={false} />
          <ContentLoader className='w-70' height={20} style={loaderTextStyle} enableAnimation={false} />
        </div>
      ))}
    </div>
  </div>
);

export interface SearchNotFoundPlaceholderProps {
  isMobile: boolean;
}

export const SearchNotFoundPlaceholder: FC<SearchNotFoundPlaceholderProps> = ({ isMobile }) =>
  isMobile ? <NotFoundPlaceholderMobile /> : <NotFoundPlaceholder />;
