import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';

export const PoweredByBezel = ({ isDark = false }) => (
  <Link href={process.env.NEXT_PUBLIC_SITE_BASE_URL || 'https://shop.getbezel.com'} target='_blank'>
    <div
      className={classNames('d-flex align-items-center z-index-5 position-relative px-3 border-radius-16', {
        'text-white': !isDark
      })}
      style={{
        border: isDark ? 'none' : '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.32)',
        backgroundColor: isDark ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.1)',
        height: '52px',
        pointerEvents: 'all'
      }}
    >
      <span className='me-2 fs-12px'>Powered by</span>
      <CloudinaryImage
        src={`logo-with-label-${isDark ? 'dark' : 'white'}.png`}
        width={66}
        height={20}
        alt='Bezel Logo'
      />
    </div>
  </Link>
);
