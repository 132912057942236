import { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FaMagnifyingGlass } from '@react-icons/all-files/fa6/FaMagnifyingGlass';
import classNames from 'classnames';

import { useViewport } from 'context/viewport';

import { MobileTopSection } from './MobileTopSection';

export const SearchSectionTestIds = {
  inputContainer: 'search-section-input-container',
  expanded: 'search-input-expanded',
  input: 'search-section-input'
};

interface SearchBarProps {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  query: string;
  isMobile?: boolean;
}

const SearchBar: FC<SearchBarProps> = ({ onSearch, query, isMobile }) => (
  <div
    className='d-flex align-items-center text-secondary border border-muted rounded-3 w-100'
    style={{ backgroundColor: '#f6f4f2' }}
  >
    <div className='text-primary px-3'>
      <FaMagnifyingGlass />
    </div>
    <FormControl
      data-testid={SearchSectionTestIds.input}
      type='search'
      placeholder={isMobile ? 'Search models...' : 'Search models, references, brands'}
      className='riforma-regular fs-16px text-primary px-1 border-0 shadow-none bg-transparent'
      style={{ height: isMobile ? '38px' : '44px' }}
      onChange={onSearch}
      value={query}
    />
  </div>
);

const scrollYValue = {
  mobile: 630,
  tablet: 460,
  desktop: 640
};

export interface SearchSectionProps {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  query: string;
  sellerLogoDark?: string;
  businessName?: string;
}

export const SearchSection: FC<SearchSectionProps> = ({ onSearch, query, sellerLogoDark, businessName }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isMobileSmall, isTabletOrMobile } = useViewport();

  const handleScroll = () => setScrollPosition(window.scrollY);

  let scrollY = scrollYValue.desktop;

  if (isMobileSmall) {
    scrollY = scrollYValue.mobile;
  } else if (isTabletOrMobile) {
    scrollY = scrollYValue.tablet;
  }

  const getOpacity = () => Math.min(1, Math.max(0, (scrollPosition - scrollY) / 100));

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        data-testid={SearchSectionTestIds.inputContainer}
        className='mt-3 mb-5 position-relative'
        style={{ zIndex: 20 }}
      >
        <SearchBar onSearch={onSearch} query={query} isMobile={isMobileSmall} />
      </div>
      <div
        data-testid={SearchSectionTestIds.expanded}
        className='position-fixed d-flex justify-content-center align-items-center top-0 start-0 end-0'
        style={{
          backgroundColor: '#fffcf9',
          zIndex: 10,
          opacity: getOpacity(),
          boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.1)'
        }}
      >
        {isMobileSmall ? (
          <div style={{ height: '115px' }} className='w-100 d-flex flex-column align-items-center mx-3'>
            <div className='w-100'>
              {(sellerLogoDark || businessName) && (
                <MobileTopSection sellerLogoDark={sellerLogoDark} title={businessName as string} />
              )}
            </div>
            <div className='w-90'>
              <SearchBar isMobile onSearch={onSearch} query={query} />
            </div>
          </div>
        ) : (
          <div
            className={classNames('d-flex align-items-center', { 'w-40': isTabletOrMobile, 'w-60': !isTabletOrMobile })}
            style={{ height: '85px' }}
          >
            <SearchBar onSearch={onSearch} query={query} />
          </div>
        )}
      </div>
    </>
  );
};
