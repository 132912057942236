import { FC } from 'react';

export const AboutSectionTestIds = {
  container: 'about-section-container'
};

export interface AboutSectionProps {
  title?: string;
  description?: string;
}

export const AboutSection: FC<AboutSectionProps> = ({ title, description }) => (
  <div data-testid={AboutSectionTestIds.container} className='mt-5 mb-5 mt-md-6 mb-md-7 riforma-regular'>
    <div className='fs-16px fs-md-18px line-height-24px line-height-md-28px mb-3 mb-md-5 riforma-medium opacity-90'>
      About us
    </div>
    {!!title && <div className='fs-24px fs-md-32px line-height-32px line-height-md-48px mb-4'>{title}</div>}
    <div className='fs-12px fs-md-14px opacity-60' style={{ whiteSpace: 'pre-wrap' }}>
      {description}
    </div>
  </div>
);
