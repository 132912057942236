import { DefaultSeoProps } from 'next-seo';

import { isOnBezelDomain } from 'lib/helpers';
import { SellerSiteMetadata } from 'types';

const bezelFavicon = '/favicon.ico';

const faviconCloudinaryTransformations = '?tx=w_16,h_16,c_pad';

export const getDefaultSellerSiteSEO = (sellerSiteMetadata?: SellerSiteMetadata | null) => {
  if (!sellerSiteMetadata) {
    return null;
  }

  const sellerSiteFavicon =
    sellerSiteMetadata?.faviconImage?.optimizedUrl?.concat(faviconCloudinaryTransformations) ||
    sellerSiteMetadata?.darkLogoImage?.optimizedUrl?.concat(faviconCloudinaryTransformations) ||
    bezelFavicon;

  const sellerSiteDefaultSEO: DefaultSeoProps = {
    openGraph: {
      siteName: sellerSiteMetadata?.businessName
    },
    // Override Bezel's apple-itunes-app and facebook meta attributes for seller sites
    additionalMetaTags: [
      {
        name: 'apple-itunes-app',
        content: ''
      },
      {
        name: 'facebook-domain-verification',
        content: ''
      }
    ],
    additionalLinkTags: [
      {
        rel: 'shortcut icon',
        href: isOnBezelDomain() ? bezelFavicon : sellerSiteFavicon
      }
    ],
    twitter: {
      site: sellerSiteMetadata?.businessName,
      cardType: 'summary_large_image'
    }
  };

  return sellerSiteDefaultSEO;
};
