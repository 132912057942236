import { JSX, useEffect, useState } from 'react';
import { FaAngleLeft } from '@react-icons/all-files/fa6/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import classNames from 'classnames';
import chunk from 'lodash/chunk';

const PAGINATION_ELEMENT_HEIGHT = '50px';
const MAX_PAGES_SHOWN_AT_ONCE = 5;

interface PaginationProps<T> {
  items: T[];
  pageSize: number;
  numberOfPages?: number;
  render: (props: { items: T[] }) => JSX.Element;
}

const scrollToTop = () => window.scrollTo({ top: 500, behavior: 'instant' });

export const Pagination = <T,>({
  items,
  pageSize,
  numberOfPages = MAX_PAGES_SHOWN_AT_ONCE,
  render
}: PaginationProps<T>) => {
  const [page, setPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);
  const chunkedItems = chunk(items, pageSize);

  useEffect(() => {
    setPage(1);
  }, [items]);

  const goPageBack = () => {
    setPage(page - 1);
    scrollToTop();
  };

  const goPageForward = () => {
    setPage(page + 1);
    scrollToTop();
  };

  const goToSpecificPage = (pageNumber: number) => {
    setPage(pageNumber);
    scrollToTop();
  };

  useEffect(() => {
    // Keeps currently selected page in the middle of the paginator.
    let lowerBound = 1;

    for (let i = Math.floor(numberOfPages / 2); i > 0; i--) {
      if (page - i >= 1) {
        lowerBound = page - i;
        break;
      }
    }

    const pages = [];

    for (let i = lowerBound; i < lowerBound + numberOfPages; i++) {
      if (i > chunkedItems.length) {
        break;
      }
      pages.push(i);
    }

    setVisiblePages(pages);
  }, [chunkedItems.length, numberOfPages, page, setVisiblePages]);

  return (
    <>
      {render({ items: chunkedItems[page - 1] })}
      <div
        className='d-flex align-items-center justify-content-center my-3'
        style={{ height: PAGINATION_ELEMENT_HEIGHT }}
      >
        {page !== 1 && (
          <div role='button' onClick={goPageBack} className='px-3 py-auto align-self-center'>
            <FaAngleLeft />
          </div>
        )}
        {visiblePages.length > 1 &&
          visiblePages.map(p => (
            <div
              role='button'
              key={`page-selector-${p}`}
              onClick={() => goToSpecificPage(p)}
              className={classNames('fs-14px px-3 text-primary', { 'opacity-40': p !== page })}
            >
              {p}
            </div>
          ))}
        {page !== chunkedItems.length && items.length > 0 && (
          <div role='button' onClick={goPageForward} className='px-3 py-auto align-self-center'>
            <FaAngleRight />
          </div>
        )}
      </div>
    </>
  );
};
